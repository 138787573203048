<template>
  <div class="switch">
    <input v-if="locked !== ''" :id="id" type="checkbox" class="switch__input" :checked="checked === '' ? true : false" @click="updateCheckbox">
    <label :for="id" class="switch__label grey-dark fw-700 fs-16"><slot>Label</slot></label>
    <IconButton v-if="locked === '' && checked === ''" background="green-15" icon="check-circle" class="switch__check" round />
    <IconButton v-if="locked === '' && checked !== ''" background="grey-light" icon="x-circle" class="switch__check" round />
    <div class="switch__saved green fs-12" v-if="showNotification">{{ $t('settings.settings-saved')}}</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import eventHub from '@/event-hub'

class Props {
  id!: string
  checked?: string
  locked?: string
}

@Options({
  name: 'Switch',
  components: {
    IconButton
  },
  emits: ['switch']
})

/**
 * Switch Checkbox type input followed by a label
 *
 * @param {string} id - ID to connect input and label
 * @param {attribute=} locked - Locks the switch button
 * @param {attribute=} checked - Checks the switch button
 */
export default class Switch extends Vue.with(Props) {
  showNotification = false

  mounted () {
    this.createShowNotificationEmitter()
  }

  createShowNotificationEmitter () {
    eventHub.$on('show-notification', (id: string) => {
      if (id === this.id) {
        this.displayNotification()
      }
    })
  }

  updateCheckbox (ev: Event) {
    const checkbox: any = ev.target
    this.$emit('switch', this.id, checkbox.checked)
  }

  displayNotification () {
    this.showNotification = true

    setTimeout(() => {
      this.hideNotification()
    }, 2000)
  }

  hideNotification () {
    this.showNotification = false
  }
}
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  position: relative;
  width: 100%;
}

.switch__input + .switch__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    border-radius: 16px;
    background-color: $grey-light;
    height: 33px;
    flex-shrink: 0;
    position: relative;
    right: 0;
    top: 0;
    transition: background-color 0.2s ease;
    width: 66px;
  }

  &::before {
    content: '';
    display: block;
    border-radius: 50%;
    background-color: $grey-dark;
    height: 25px;
    position: absolute;
    right: 0;
    transform: translateX(-35px);
    transition: transform 0.2s ease, background-color 0.2s ease;
    width: 25px;
    z-index: 1;
  }

  &:hover {
    cursor: pointer;
  }
}

.switch__input:checked + .switch__label {
  &::after {
    content: '';
    background-color: $green-15;
  }

  &::before {
    content: '';
    background-color: $green;
    transform: translateX(-5px);
  }
}

.switch__input:disabled + .switch__label {
  &::after {
    content: '';
    background-color: $green-15;
    width: 33px;
  }

  &::before {
    content: '';
    background-color: $green;
  }
}

.switch__input {
  position: absolute;
  opacity: 0;
}

.switch__check {
  pointer-events: none;
}

.switch__saved {
  background-color: transparent;
  position: absolute;
  right: 0;
  bottom: -5px;
}
</style>
