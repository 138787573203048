
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import eventHub from '@/event-hub'

class Props {
  id!: string
  checked?: string
  locked?: string
}

@Options({
  name: 'Switch',
  components: {
    IconButton
  },
  emits: ['switch']
})

/**
 * Switch Checkbox type input followed by a label
 *
 * @param {string} id - ID to connect input and label
 * @param {attribute=} locked - Locks the switch button
 * @param {attribute=} checked - Checks the switch button
 */
export default class Switch extends Vue.with(Props) {
  showNotification = false

  mounted () {
    this.createShowNotificationEmitter()
  }

  createShowNotificationEmitter () {
    eventHub.$on('show-notification', (id: string) => {
      if (id === this.id) {
        this.displayNotification()
      }
    })
  }

  updateCheckbox (ev: Event) {
    const checkbox: any = ev.target
    this.$emit('switch', this.id, checkbox.checked)
  }

  displayNotification () {
    this.showNotification = true

    setTimeout(() => {
      this.hideNotification()
    }, 2000)
  }

  hideNotification () {
    this.showNotification = false
  }
}
